import axios from 'axios'
import { Tool } from '@/assets/js/util/tool'
import router from '@/settings/router/'
import { Cache, DataType, CustomerBehaviorMap } from '@/assets/js/util'
const tool = new Tool()
const cache = new Cache()
// const errorResultCode = [
//   DataType.AjaxResultCodeEnum.CREDENTIAL_IS_INVALID.value,
//   DataType.AjaxResultCodeEnum.ACCOUNT_IS_INVALID.value,
//   DataType.AjaxResultCodeEnum.MISSING_IDENTITY_INFORMATION.value,
//   DataType.AjaxResultCodeEnum.WRONG_GRANT_TYPE.value
// ]
const http = axios.create({})
window._axiosPromiseArr = []
const ajax = {
  get (url, input, success, failure) {
    return axios.get(url, { params: input }).then((result) => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  post (url, input, success, failure) {
    return axios.post(url, input).then((result) => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  put (url, input, success, failure) {
    return axios.put(url, input).then((result) => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  delete (url, input, success, failure) {
    return axios.delete(url, { params: input }).then((result) => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  }
}
let requestApiStatus = 'approve'
axios.interceptors.request.use(
  config => {
    const accessToken = cache.getAccessToken()
    const userCredential = cache.getUserCredential()
    if (tool.isNotEmpty(accessToken)) {
      config.headers.common['Access-Token'] = accessToken
    }
    if (tool.isNotEmpty(userCredential)) {
      config.headers.common['User-Credential'] = userCredential
    }
    const originalUrl = config.url
    const path = originalUrl.substring(originalUrl.lastIndexOf('api/') + 3)
    const method = config.method
    const ajaxMap = CustomerBehaviorMap.ajaxMap[path]
    const route = window.location.href.substring(window.location.href.lastIndexOf('#') + 1)
    if (tool.isNotEmpty(ajaxMap)) {
      if (ajaxMap.method === method.toLowerCase()) {
        if (tool.isNotEmpty(ajaxMap.children)) {
          for (const item of ajaxMap.children) {
            if (route.indexOf(item.route) !== -1) {
              // get multiple report Api can be used only once
              if (item.eventSource === DataType.EventSourceEnum.GUIDANCE_API_DL_GET_MULTIPLE_REPORTS.value) {
                if (requestApiStatus === 'approve') {
                  addCustomerBehaviorRecords(route, item.eventSource)
                  requestApiStatus = 'refuse'
                }
                break
              } else {
                addCustomerBehaviorRecords(route, item.eventSource)
                // 从guidance到新页面会记录请求，同时重置status,下次访问DL可以再次记录行为
                requestApiStatus = 'approve'
                break
              }
            }
          }
        } else {
          addCustomerBehaviorRecords(route, ajaxMap.eventSource)
          // 从guidance到新页面会记录请求，同时重置status,下次访问DL可以再次记录行为
          requestApiStatus = 'approve'
        }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error).catch((e) => {})
  }
)

axios.interceptors.response.use(
  response => {
    if (tool.isNotEmpty(response)) {
      if (response.data.code === DataType.SYSTEM_ERROR_CODE.INVALID_LOAN.value) {
        // 链接失效
        router.replace({ path: '/notification/302' })
      } else if (response.data.code === DataType.SYSTEM_ERROR_CODE.NO_IDENTITY.value || response.data.code === DataType.SYSTEM_ERROR_CODE.INVALID_IDENTITY.value) {
        // 未登录或token过期
        router.replace({ path: '/sign-in' })
      }
    }
    return response
  },
  error => {
    return Promise.reject(error).catch((e) => {
    })
  }
)

function resultHandler (result, resolve, reject, success, failure) {
  if (tool.isNotEmpty(result)) {
    const resultData = result.data

    if (result.status === 200) {
      if (resultData.code !== 1) {
        if (failure) {
          failure(resultData)
        }

        return Promise.reject(result.data).catch(function (reason) {
        })
      } else {
        if (success) {
          success(resultData.data)
        }

        return Promise.resolve(resultData.data)
      }
    } else {
      if (failure) {
        failure(resultData)
      }

      return Promise.reject(result.statusText)
    }
  } else {
    return Promise.reject(new Error('')).catch((e) => {
    })
  }
}

function addCustomerBehaviorRecords (route, eventSource) {
  // if (route.indexOf('/welcome') !== -1) {
  //   CommonRequest.addCustomerBehaviorRecords(tool.isPC() ? '/web/welcome' : '/welcome', DataType.EventTypeEnum.ACCESS_API.value, eventSource)
  // } else {
  //   CommonRequest.addCustomerBehaviorRecords(route, DataType.EventTypeEnum.ACCESS_API.value, eventSource)
  // }
}

export { http, ajax }
