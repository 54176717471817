export const WapConfig = [
  {
    path: '/',
    redirect: '/sign-in'
  },
  {
    path: '/',
    component: () => import('@/layouts/WapBlankLayout'),
    children: [
      {
        path: '/404',
        meta: {
          title: 'NotFound',
          code: 10000001
        },
        component: () => import('@/views/error/404')
      },
      {
        path: '/403',
        meta: {
          title: 'Deny',
          code: 10000001
        },
        component: () => import('@/views/error/403')
      },
      {
        path: '/500',
        meta: {
          title: 'Error',
          code: 10000001
        },
        component: () => import('@/views/error/500')
      },
      {
        path: 'application/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'returning/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'complete-information/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'pending-application/:resource',
        component: () => import('@/views/system/Invitation')
      },
      {
        path: 'guidance', // region System with Blank
        component: () => import('@/views/guidance/Index')
      },
      // 邮件链接到协议、签字
      {
        path: 'signature/:resource',
        component: () => import('@/views/online-signature/LoadResource')
      },
      {
        path: 'simulator',
        component: () => import('@/views/system/Simulator')
      }
    ]
  },
  {
    path: '/disbursement',
    component: () => import('@/layouts/NavigationLayout'),
    children: [
      {
        path: 'choose-loan-amount',
        meta: {
          title: 'Apply for New Loan',
          subtitle: 'Choose Loan Amount',
          mode: 'dark',
          code: 10000003,
          to: '/home',
          sameDayTip: true
        },
        component: () => import('@/views/disbursement/ChooseLoanAmount')
      },
      {
        path: 'choose-payment-mode',
        meta: {
          title: 'Apply for New Loan',
          mode: 'light',
          subtitle: 'Choose Repayment Mode',
          code: 10000003,
          to: '/disbursement/choose-loan-amount',
          sameDayTip: true
        },
        component: () => import('@/views/disbursement/ChoosePaymentMode')
      },
      {
        path: 'choose-bank-account',
        meta: {
          title: 'Apply for New Loan',
          mode: 'light',
          subtitle: 'Date & Bank Account',
          sameDayTip: true,
          changeDateTip: true,
          code: 10000003,
          to: '/disbursement/choose-payment-mode'
        },
        component: () => import('@/views/disbursement/ChooseBankAccount')
      },
      {
        path: 'choose-loan-term',
        meta: {
          title: 'Apply for New Loan',
          mode: 'light',
          subtitle: 'Review Loan Term',
          sameDayTip: true,
          changeDateTip: true,
          code: 10000003,
          to: '/disbursement/choose-bank-account'
        },
        component: () => import('@/views/disbursement/ChooseLoanTerm')
      },
      {
        path: 'confirm-details',
        name: 'Apply Loan Details',
        meta: {
          title: 'Apply for New Loan',
          mode: 'light',
          subtitle: 'Confirm Loan Details',
          sameDayTip: true,
          changeDateTip: true,
          code: 10000003,
          to: '/disbursement/choose-bank-account'
        },
        component: () => import('@/views/disbursement/ConfirmDetails')
      },
      {
        path: 'sign-loan-agreement',
        name: 'Sign Loan Agreement',
        meta: {
          mode: 'none-footer',
          title: 'Apply for New Loan',
          to: '/disbursement/confirm-details',
          sameDayTip: true,
          changeDateTip: true
        },
        component: () => import('@/views/disbursement/SignLoanAgreement')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/WapNavigationLayout'),
    children: [
      {
        path: 'notification/:code',
        component: () => import('@/views/system/Notification')
      },
      {
        path: '/loan-agreement',
        name: 'Loan Agreement',
        meta: {
          title: 'Apply for New Loan'
        },
        component: () => import('@/views/online-signature/LoanAgreement')
      }
    ]
  },
  // region Home
  {
    path: '/',
    component: () => import('@/layouts/HomeLayout'),
    children: [
      {
        path: 'home',
        meta: {
          title: 'Home',
          code: 10000001
        },
        component: () => import('@/views/business/Home')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/NavigationLayout'),
    children: [
      {
        path: 'change-password',
        meta: {
          subtitle: 'Change Password'
        },
        component: () => import('@/views/system/ChangePassword')
      }
    ]
  },
  // region Repayment
  {
    path: '/repayment',
    component: () => import('@/layouts/NavigationLayout'),
    children: [
      {
        path: 'loan-details',
        name: 'Repayment Loan Detail',
        meta: {
          title: 'Loan Details',
          mode: 'dark',
          code: 10000006,
          to: '/home'
        },
        component: () => import('@/views/repayment/LoanDetails')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/ToolbarLayout'),
    children: [
      {
        path: 'welcome',
        meta: {
          topTransparent: false
        },
        component: () => import('@/views/system/WapWelcome')
      },
      {
        path: 'consent',
        meta: {
          topTransparent: false
        },
        component: () => import('@/views/system/WapWelcome')
      },
      {
        path: 'sign-in',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/system/SignIn')
      },
      {
        path: 'send-set-link',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/system/SendSetLink')
      },
      {
        path: 'create-account/:resource',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/system/CreateAccount')
      },
      {
        path: 'send-reset-link',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/system/SendResetLink')
      },
      {
        path: 'reset-password/:resource',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/system/ResetPassword')
      },
      {
        path: 'bank-statement-require',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/bank-statement/Requirement')
      },
      {
        path: 'upload-bank-statement',
        meta: {
          topTransparent: true
        },
        component: () => import('@/views/bank-statement/UploadBankStatement')
      }
    ]
  },
  {
    path: '/coupon',
    component: () => import('@/layouts/CrocNavigationLayout'),
    children: [
      {
        path: 'verification/:identifier',
        component: () => import('@/views/collection/EmailVerification')
      },
      {
        path: 'overview',
        meta: {
          title: '',
          mode: 'light'
        },
        component: () => import('@/views/collection/PromotionPlan')
      },
      {
        path: 'schedule',
        meta: {
          title: 'Schedule New Payment',
          mode: ''
        },
        component: () => import('@/views/collection/ScheduleNewPayment')
      },
      {
        path: 'congratulations-page',
        meta: {
          title: '',
          mode: ''
        },
        component: () => import('@/views/collection/CongratulationsPage')
      }
    ]
  }
]
