import DataType from '@/assets/js/util/dataType'
import BigDecimal from 'js-big-decimal'
import { nanoid } from 'nanoid'
import store from '../store'
import momentTz from 'moment-timezone'
import { capitalize } from 'lodash'
const moment = require('moment')
const _ = require('lodash')

class Tool {
  getEnumTextByValue (e, value) {
    for (const item in e) {
      // eslint-disable-next-line eqeqeq
      if (e[item].value == value) {
        return e[item].text
      }
    }

    return ''
  }

  getEnumByValue (e, value) {
    for (const item in e) {
      // eslint-disable-next-line eqeqeq
      if (e[item].value == value) {
        return e[item]
      }
    }

    return null
  }

  isNull (value) {
    return value === undefined || value === null
  }

  isNotNull (value) {
    return value !== undefined && value !== null
  }

  isNotEmpty (value) {
    if (_.isBoolean(value) || _.isNumber(value) || _.isFunction(value)) {
      return true
    }
    return !_.isEqual(value, 'N/A') && !_.isEmpty(value)
    // return (
    //   value !== undefined &&
    //   value !== '' &&
    //   value !== null &&
    //   value !== 'N/A' &&
    //   JSON.stringify(value) !== '{}' &&
    //   JSON.stringify(value) !== '[]'
    // )
  }

  isEmpty (value) {
    if (_.isBoolean(value) || _.isNumber(value) || _.isFunction(value)) {
      return false
    }
    return _.isEqual(value, 'N/A') || _.isEmpty(value)
    // return (
    //   value === undefined ||
    //   value === null ||
    //   value === 'N/A' ||
    //   value === '' ||
    //   JSON.stringify(value) === '{}' ||
    //   JSON.stringify(value) === '[]'
    // )
  }

  getRandomKey (index) {
    return index + Math.random()
  }

  getFullName (data) {
    if (this.isEmpty(data)) {
      return ''
    }
    try {
      if (typeof data === 'string') {
        data = JSON.parse(data)
      }
    } catch (e) {}
    let fullName = ''
    if (
      Object.prototype.hasOwnProperty.call(data, 'firstName') &&
      this.isNotEmpty(data.firstName)
    ) {
      data.firstName = data.firstName.toString().trim()
      if (this.isNotEmpty(data.firstName)) {
        fullName = _.capitalize(data.firstName)
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(data, 'middleName') &&
      this.isNotEmpty(data.middleName)
    ) {
      data.middleName = data.middleName.toString().trim()
      if (this.isNotEmpty(data.middleName)) {
        fullName += ` ${_.capitalize(data.middleName)}`
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(data, 'lastName') &&
      this.isNotEmpty(data.lastName)
    ) {
      data.lastName = data.lastName.toString().trim()
      if (this.isNotEmpty(data.lastName)) {
        fullName += ` ${_.capitalize(data.lastName)}`
      }
    }

    return fullName
  }

  getSystemCode () {
    return DataType.SystemCodeEnum.PHOENIX.value
  }

  formatPersonalName (data) {
    data.firstName = this.formatName(data.firstName)
    data.middleName = this.formatName(data.middleName)
    data.lastName = this.formatName(data.lastName)
    return data
  }

  formatName (name) {
    if (this.isNotEmpty(name)) {
      return _.capitalize(name)
    } else {
      return null
    }
  }

  formatPhone (phone) {
    if (this.isNotEmpty(phone)) {
      try {
        phone = phone.replace(/[^0-9]/gi, '')
        return (
          '(' +
          phone.substring(0, 3) +
          ') ' +
          phone.substring(3, 6) +
          '-' +
          phone.substring(6, phone.length)
        )
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  formatPhoneWithStar (phone) {
    if (this.isNotEmpty(phone)) {
      try {
        phone = phone.replace(/[^0-9]/gi, '')
        return (
          '(' +
          phone.substring(0, 3) +
          ') ' +
          '***' +
          '-' +
          phone.substring(6, phone.length)
        )
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  formatEmailWithStar (email) {
    if (this.isNotEmpty(email)) {
      try {
        const splitArray = email.split('@')
        return splitArray[0].substring(0, 1) + '****' + '@' + splitArray[1]
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  formatPercent (num, digits) {
    let result = 'N/A'
    try {
      const floatNum = parseFloat(num)
      if (this.isEmpty(digits)) {
        digits = 2
      }

      const bigDecimalNum = new BigDecimal(floatNum)
      const hundred = new BigDecimal(100)
      const bigDecimalHundredNum = bigDecimalNum.multiply(hundred)

      result = bigDecimalHundredNum
        .round(digits, BigDecimal.RoundingModes.HALF_UP)
        .getValue()
      return result + '%'
    } catch (e) {
      return result
    }
  }

  getArrayByEnum (e) {
    const result = []
    for (const item in e) {
      result.push(e[item])
    }
    return result
  }

  // TODO: Modify this method to a more general usage
  getWapArrayByEnum (e) {
    const result = []
    for (const item in e) {
      if (typeof e[item] !== 'function') {
        result.push({ value: e[item].value, text: e[item].text })
      }
    }
    return result
  }

  formatDateWithPattern (str, patter) {
    if (this.isEmpty(str) || this.isEmpty(patter)) {
      return ''
    }
    const date = moment(str)
    if (!date.isValid()) {
      throw new Error('Invalid date')
    }
    return date.format(patter.toUpperCase())
  }

  getCurrentUSTime (patter) {
    if (this.isEmpty(patter)) {
      return momentTz(new Date())
        .tz('America/New_York')
        .format()
    }
    return momentTz(new Date())
      .tz('America/New_York')
      .format(patter.toUpperCase())
  }

  isWeekend (str) {
    return moment(str).day() === 0 || moment(str).day() === 6
  }

  isHoliday (str) {
    let holidays = store.getters.getHoliday
    holidays = this.isEmpty(holidays) ? [] : holidays
    return holidays.includes(str)
  }

  formatSsnWithStar (ssn) {
    if (this.isNotEmpty(ssn)) {
      try {
        ssn = ssn.replace(/[^0-9]/gi, '')
        return '***-**-' + ssn.substring(5, ssn.length)
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  /**
   * 随机产生n位随机数字的字符串
   * @param n
   * @returns {string}
   */
  getRandomNum (n) {
    let result = ''
    for (let i = 0; i < n; i++) {
      result += Math.floor(Math.random() * 10)
    }
    return result
  }

  generalNanoid () {
    return nanoid()
  }

  isPC () {
    const userAgentInfo = navigator.userAgent
    const Agents = [
      'Android',
      'iPhone',
      'SymbianOS',
      'Windows Phone',
      'iPad',
      'iPod'
    ]
    let flag = true
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false
        break
      }
    }
    return flag
  }

  setPopupInformation (message, color) {
    store.commit('setPopupInformation', {
      message,
      color
    })
  }

  isOvertime (dateStr, dayNum) {
    return moment(dateStr, 'YYYY-MM-DD HH:mm:SS')
      .add(dayNum, 'd')
      .isBefore(moment(), 'second')
  }

  getCusAccType (val) {
    return 'checking'
    // switch (val) {
    //   case DataType.BankAccountTypeEnum.CHECKING_ACCOUNT.value:
    //     return 'checking'
    //   case DataType.BankAccountTypeEnum.SAVINGS_ACCOUNT.value:
    //     return 'saving'
    //   case DataType.BankAccountTypeEnum.MONEY_MARKET.value:
    //     return ''
    //   default:
    //     return ''
    // }
  }

  sectionRender (field, data, currentLockedId) {
    let result = ''

    if (this.isNotEmpty(data)) {
      const compositeKey = field.compositeKey || []
      let templateFormat = field.templateFormat
      if (templateFormat?.hasPlaceholder()) {
        const keys = templateFormat.fetchPlaceholder()
        for (const key of keys) {
          const realKey = key.replace(/{|}/g, '')
          const composite = this.renderCompositeData(compositeKey, realKey, data) || ''
          templateFormat = templateFormat.replace(key, composite)
        }
        // 组合的值去掉重复的,
        templateFormat = templateFormat.replace(', ,', ',')
        result = templateFormat || 'N/A'
      }
    } else {
      result = 'N/A'
    }

    if (this.isNotEmpty(field.foregroundProperty)) {
      // 首字母大写的转换
      if (this.isNotEmpty(field.foregroundProperty.capitalizeFirst) && result !== 'N/A') {
        const control = field.foregroundProperty.capitalizeFirst === 'true'
        result = control ? this.capitalizeFirstWord(result) : this.capitalizeWords(result)
      }
      // 需要加密数据的字段
      if (this.isNotEmpty(field.foregroundProperty.format) && result !== 'N/A') {
        result = this.lockFormat(field.foregroundProperty.format, result)
      }
    }

    return result
  }

  capitalizeWords (str) {
    if (this.isEmpty(str)) return ''
    // 转换每个单词的首字母为大写，其他字母为小写
    return str.replace(/\b\w+\b/g, (match) => {
      return capitalize(match)
    })
  }

  capitalizeFirstWord (str) {
    return capitalize(str)
  }

  lockFormat (format, value) {
    let result = null
    if (format === 'phone') {
      result = value.replace(/\((\d{3})\)\s(\d{3})-(\d{4})/, '(***) ***-$3')
    } else if (format === 'ssn') {
      result = value.substring(0, 3).replace(/\d/ig, '*') + value.substring(3, 6).replace(/\d/ig, '*') + value.substring(6, value.length)
    } else if (format === 'email' || format === 'account') {
      result = '*****' + value.slice(-4)
    }
    return result
  }

  renderCompositeData (compositeKey, key, data) {
    let result = ''
    const find = compositeKey.find(i => i.key === key)
    if (this.isNotEmpty(find)) {
      if (find.format) result = this.formatData(data[key], find.format)
      if (find.dataValue) { result = this.getEnumTextFromDictionary(find.dataValue, data[key]) }
    } else {
      result = data[key]
    }
    return result
  }

  formatData (data, format) {
    switch (format) {
      case 'ssn':
        return this.formatSsn(data)
      case 'currency':
        return this.formatCurrency(data)
      case 'phone':
        return this.formatPhone(data)
      case 'percent':
        return this.formatPercent(data)
      default:
        return data
    }
  }

  formatSsn (ssn) {
    if (this.isNotEmpty(ssn)) {
      try {
        ssn = ssn.toString().replace(/[^0-9]/gi, '')
        return (
          ssn.substring(0, 3) +
          '-' +
          ssn.substring(3, 5) +
          '-' +
          ssn.substring(5, ssn.length)
        )
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  }

  getEnumTextFromDictionary (e, value, needToConvert = true) {
    if (needToConvert) {
      value = String(value)
    }
    const findItem = e.find(item => String(item.value) === value)
    return findItem ? findItem.text : ''
  }

  formatDateStrByPattern (value, pattern) {
    pattern = this.isEmpty(pattern) ? 'MMM DD, YYYY' : pattern

    switch (pattern) {
      case 'hh:mm a, MMM dd, yyyy':
        pattern = 'hh:mm a, MMM DD, YYYY'
        break
      case 'hh:mm a z, MMM d, yyyy':
        pattern = 'hh:mm A z, MMM D, YYYY'
        break
      case 'MMM dd, yyyy':
        pattern = 'MMM DD, YYYY'
        break
      case 'MMM d, yyyy':
        pattern = 'MMM D, YYYY'
        break
      case 'yyyy-MM-dd':
        pattern = 'yyyy-MM-DD'
        break
      case 'hh:mm a':
        pattern = 'hh:mm a'
        break
      case 'hh:mm a z':
        pattern = 'hh:mm A z'
        break
      default:
        break
    }

    return moment(value).format(pattern)
  }

  deepCompare (x, y) {
    let i, l, leftChain, rightChain

    function compare2Objects (x, y) {
      let p

      // remember that NaN === NaN returns false
      // and isNaN(undefined) returns true
      if (
        isNaN(x) &&
        isNaN(y) &&
        typeof x === 'number' &&
        typeof y === 'number'
      ) {
        return true
      }

      // Compare primitives and functions.
      // Check if both arguments link to the same object.
      // Especially useful on the step where we compare prototypes
      if (x === y) {
        return true
      }

      // Works in case when functions are created in constructor.
      // Comparing dates is a common scenario. Another built-ins?
      // We can even handle functions passed across iframes
      if (
        (typeof x === 'function' && typeof y === 'function') ||
        (x instanceof Date && y instanceof Date) ||
        (x instanceof RegExp && y instanceof RegExp) ||
        (x instanceof String && y instanceof String) ||
        (x instanceof Number && y instanceof Number)
      ) {
        return x.toString() === y.toString()
      }

      // At last checking prototypes as good as we can
      if (!(x instanceof Object && y instanceof Object)) {
        return false
      }

      if (
        Object.prototype.hasOwnProperty.call(x, y) ||
        Object.prototype.hasOwnProperty.call(y, x)
      ) {
        return false
      }

      if (x.constructor !== y.constructor) {
        return false
      }

      if (x.prototype !== y.prototype) {
        return false
      }

      // Check for infinitive linking loops
      if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
        return false
      }

      // Quick checking of one object being a subset of another.
      // todo: cache the structure of arguments[0] for performance
      for (p in y) {
        if (
          Object.prototype.hasOwnProperty.call(y, p) !==
          Object.prototype.hasOwnProperty.call(x, p)
        ) {
          return false
        } else if (typeof y[p] !== typeof x[p]) {
          return false
        }
      }

      for (p in x) {
        if (
          Object.prototype.hasOwnProperty.call(y, p) !==
          Object.prototype.hasOwnProperty.call(x, p)
        ) {
          return false
        } else if (typeof y[p] !== typeof x[p]) {
          return false
        }

        switch (typeof x[p]) {
          case 'object':
          case 'function':
            leftChain.push(x)
            rightChain.push(y)

            if (!compare2Objects(x[p], y[p])) {
              return false
            }

            leftChain.pop()
            rightChain.pop()
            break

          default:
            if (x[p] !== y[p]) {
              return false
            }
            break
        }
      }

      return true
    }

    if (arguments.length < 1) {
      return true // Die silently? Don't know how to handle such case, please help...
      // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {
      leftChain = [] // Todo: this can be cached
      rightChain = []

      if (!compare2Objects(arguments[0], arguments[i])) {
        return false
      }
    }

    return true
  }

  formatCurrency (num) {
    if (this.isNotEmpty(num)) {
      if (Number(num) === 0) {
        return '0.00'
      }
      if (num) {
        num = Number(num).toFixed(2)
        // eslint-disable-next-line no-useless-escape
        num = num.toString().replace(/\$|\,/g, '')
        // 如果num不是数字，则将num置0，并返回
        if (num === '' || isNaN(num)) {
          return 'Not a Number ! '
        }
        // 如果num是负数，则获取她的符号
        const sign = num.indexOf('-') > -1 ? '-' : ''
        let cents =
          num.indexOf('.') > -1 ? num.substr(num.indexOf('.')) : '.00'
        for (let i = 0; i < 3 - cents.length; ++i) {
          cents = cents + '0'
        }

        let numStart = 0
        if (sign === '-') {
          numStart = 1
        }
        num =
          num.indexOf('.') > -1
            ? num.substring(numStart, num.indexOf('.'))
            : num.substr(numStart)
        /*
          也可以这样想象，现在有一串数字字符串在你面前，如果让你给他家千分位的逗号的话，你是怎么来思考和操作的?
          字符串长度为0/1/2/3时都不用添加
          字符串长度大于3的时候，从右往左数，有三位字符就加一个逗号，然后继续往前数，直到不到往前数少于三位字符为止
         */
        for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
          num =
            num.substring(0, num.length - (4 * i + 3)) +
            ',' +
            num.substring(num.length - (4 * i + 3))
        }

        if (num.length > 0 && num.substr(0, 1) === ',') {
          num.slice(0)
        }

        // 将数据（符号、整数部分、小数部分）整体组合返回
        if (sign === '-') {
          return '(' + num + cents + ')'
        } else {
          return num + cents
        }
      }
    } else {
      return ''
    }
  }

  getConsentDescription (protocol) {
    let text = 'By checking the boxes above and clicking <strong>CONTINUE</strong>, you expressly consent to '
    const militaryPrefix = 'self-identification for purposes of the '

    const descriptionArray = []
    for (let i = 0; i < protocol.length; i++) {
      const checkbox = protocol[i].check
      if (checkbox) descriptionArray.push(protocol[i])
    }

    if (descriptionArray.length === 1) {
      if (descriptionArray[0].templateLabel?.toUpperCase()?.indexOf('MILITARY') !== -1) {
        text += `${militaryPrefix}<strong class="consents-prominent">${descriptionArray[0].templateLabel}</strong>`
      } else {
        text += `<strong class="consents-prominent">${descriptionArray[0].templateLabel}</strong>`
      }
    } else {
      for (let i = 0; i < descriptionArray.length; i++) {
        if (i === descriptionArray.length - 1) {
          if (descriptionArray[i].templateLabel?.toUpperCase()?.indexOf('MILITARY') !== -1) {
            text += `and ${militaryPrefix}<strong class="consents-prominent">${descriptionArray[i].templateLabel}</strong>`
          } else {
            text += `and <strong class="consents-prominent">${descriptionArray[i].templateLabel}</strong>`
          }
        } else {
          text += `<strong class="consents-prominent">${descriptionArray[i].templateLabel}</strong>, `
        }
      }
    }

    return text + '.'
  }

  validateEmail (email) {
    return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)
  }

  validatePassword (password) {
    if (password.length < 8) {
      return 'Password should be 8 or more characters.'
    }
    if (!/[a-z]+/.test(password) || !/[A-Z]+/.test(password)) {
      return 'Password has Upper & Lowercase letters.'
    }
    if (!/\d+/.test(password)) {
      return 'Password should be at least one number.'
    }
    if (/(.)\1{2}/.test(password)) {
      return 'Password has too many consecutive identical characters.'
    }
    return true
  }
}

export { Tool }
