export default {
  state: {
    applicationNo: '',
    requestId: null
  },
  mutations: {
    setApplicationNo (state, data) {
      state.applicationNo = data
    },
    setRequestId (state, data) {
      state.requestId = data
    }
  },
  actions: {
  },
  getters: {
    getApplicationNo: state => state.applicationNo,
    getRequestId: state => state.requestId
  }
}
