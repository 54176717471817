export default {
  state: {
    loan: {
      loanNo: '',
      loanId: '',
      loanStatus: null,
      loanSubStatus: null
    },
    repaymentLoanInfo: {},
    chooseTerms: {},
    approvedAmount: 0,
    program: {},
    preLoanFlag: null // 从welcome page解析出来，用户以前有过一笔成功的loan，就会有这个flag
  },
  mutations: {
    setLoan (state, data) {
      state.loan = data
    },
    setRepaymentLoanInfo (state, data) {
      state.repaymentLoanInfo = data
    },
    setChooseTerms (state, data) {
      state.chooseTerms = data
    },
    setApprovedAmount (state, data) {
      state.approvedAmount = data
    },
    setProgram (state, data) {
      state.program = data
    },
    setPreLoanFlag (state, data) {
      state.preLoanFlag = data
    }
  },
  actions: {
  },
  getters: {
    getLoan: state => state.loan,
    getRepaymentLoanInfo: state => state.repaymentLoanInfo,
    getChooseTerms: state => state.chooseTerms,
    getApprovedAmount: state => state.approvedAmount
  }
}
