import Vue from 'vue'
import { Tool } from '@/assets/js/util'

const tool = new Tool()

Vue.directive('page-behavior', {
  bind (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      let path = binding.value.path
      if (path.indexOf('/welcome') !== -1) {
        path = tool.isPC() ? '/web/welcome' : '/welcome'
      }
      // CommonRequest.addCustomerBehaviorRecords(path, DataType.EventTypeEnum.ENTER_PAGE.value, binding.value.eventSource)
    }
  },
  unbind (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      let path = binding.value.path
      if (path.indexOf('/welcome') !== -1) {
        path = tool.isPC() ? '/web/welcome' : '/welcome'
      }
      // CommonRequest.addCustomerBehaviorRecords(path, DataType.EventTypeEnum.LEAVE_PAGE.value, binding.value.eventSource)
    }
  }
})

Vue.directive('page-behavior-create-account', {
  bind (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      // CommonRequest.addCustomerBehaviorRecords(binding.value.path, DataType.EventTypeEnum.ENTER_PAGE.value, binding.value.eventSource, binding.value.openId)
    }
  },
  unbind (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      // CommonRequest.addCustomerBehaviorRecords(binding.value.path, DataType.EventTypeEnum.LEAVE_PAGE.value, binding.value.eventSource, binding.value.openId)
    }
  }
})

Vue.directive('min-height', {
  inserted (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      element.style.display = 'flex'
      element.style.flexDirection = 'column'
      element.style.justifyContent = 'center'
      element.style.alignItems = 'center'
      element.style.minHeight = '{0}px'.format(binding.value)
    }
  }
})

Vue.directive('warkarea-width', {
  inserted (element, binding, node) {
    if (Object.prototype.hasOwnProperty.call(binding, 'value')) {
      element.style.flex = '0 0 {0}px'.format(binding.value)
    }
  }
})

Vue.directive('workarea', {
  inserted (element, binding, node) {
    let secondaryWidth = 0

    const secondaryWorkareas = element.getElementsByClassName('workarea-secondary')

    for (const secondaryWorkarea of secondaryWorkareas) {
      secondaryWidth += secondaryWorkarea.offsetWidth
    }

    const primaryWorkareas = element.getElementsByClassName('workarea-primary')
    if (primaryWorkareas.length === 1) {
      primaryWorkareas[0].style.width = '{0}px'.format(element.offsetWidth - secondaryWidth)
    }
  }
})

/** SUSPENDED */
Vue.directive('fill-height', {
  inserted (element, binding, node) {
    const classList = element.classList
    if (classList.contains('v-data-table')) {
      element.style.height = '100%'

      let footerHeight = 0
      const footer = element.getElementsByClassName('v-data-footer')
      if (footer.length === 1) {
        footerHeight = footer[0].offsetHeight
      }

      const wrapper = element.getElementsByClassName('v-data-table__wrapper')
      if (wrapper.length === 1) {
        wrapper[0].style.height = '{0}px'.format(element.offsetHeight - footerHeight)
      }
    } else {
      const parentElement = element.parentElement
      const parentHeight = parentElement.offsetHeight
      let othersHeight = 0
      for (const childElement of parentElement.children) {
        if (childElement !== element) {
          othersHeight += childElement.offsetHeight
        }
      }
      element.style.height = '{0}px'.format(parentHeight - othersHeight)
    }
  }
})
