import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import { validation } from './config'

const dictionary = {
  en: {
    messages: {
      required: (field) => `${field} can't be empty`,
      email: field => `${field} must be a valid email`,
      max: (field, [matchValue]) => `${field} should not be greater than ${matchValue} characters`
    }
  }
}

// Refer to the above settings
Validator.localize(dictionary)

Validator.extend('notEmpty', validation.notEmpty)
Validator.extend('phone', validation.phone)
Validator.extend('fax', validation.fax)
Validator.extend('number', validation.number)
Validator.extend('currency', validation.currency)
Validator.extend('specialCharacters', validation.specialCharacters)
Validator.extend('bankRoutingNo', validation.bankRoutingNo)
Validator.extend('bankAccountNo', validation.bankAccountNo)
Validator.extend('ssn', validation.ssn)
Validator.extend('zip', validation.zip)
Validator.extend('matchTailNo', validation.matchTailNo)
Validator.extend('alphabetWithSpace', validation.alphabetWithSpace)
Validator.extend('name', validation.name)
Validator.extend('noSpaceAtStartAndEnd', validation.noSpaceAtStartAndEnd)
Validator.extend('noContinuousSpace', validation.noContinuousSpace)
Validator.extend('email', validation.email)
Validator.extend('continuousSpace', validation.continuousSpace)
Validator.extend('positiveInteger', validation.positiveInteger)

const config = {
  errorBagName: 'errors', // change if property conflicts.
  fieldsBagName: 'fieldBags', // 报冲突时 可自定义修改字段名称
  delay: 0, // 错误提示的延迟时间
  strict: true, // 没有设置规则的表单不进行校验，
  enableAutoClasses: false,
  locale: 'en', // 对语言（中文）的配置
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty' // control has been interacted with
  },
  events: 'input|blur', //* *input|blur** 在用户输入和表单失去焦点时都进行校验 可单独写  blur或input
  inject: true
}
/*
 * Event trigger rules:
 * after input in the input box, click event trigger verification.
 * The default is real-time verification
 */
Vue.use(VeeValidate, config)
