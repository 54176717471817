const state = {
  subtitle: '', // business light layout
  sessionId: '',
  redirectUrlParameter: '',
  hasQualifiedPrincipal: false
}

const getters = {
  getSubtitle: state => state.subtitle,
  getSessionId: state => state.sessionId,
  getRedirectUrlParameter: state => state.redirectUrlParameter,
  getHasQualifiedPrincipal: state => state.hasQualifiedPrincipal
}

const actions = {}

const mutations = {
  setSubtitle (state, subtitle) {
    state.subtitle = subtitle
  },
  setSessionId (state, sessionId) {
    state.sessionId = sessionId
  },
  setRedirectUrlParameter (state, data) {
    state.redirectUrlParameter = data
  },
  setHasQualifiedPrincipal (state, data) {
    state.hasQualifiedPrincipal = data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
