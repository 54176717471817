import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-loan/online'

const OnlineApi = {
  // Collect部分获取
  getPortfolioIdByCode (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/portfolioId/get`,
      input,
      success,
      failure
    )
  },
  authenticateIdentity (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/identity/authenticate`,
      input,
      success,
      failure
    )
  },
  getPaymentPersonalInfo (input, success, failure) {
    return ajax.get(
      `${BASE_URL}/loan/get-personal-info`,
      input,
      success,
      failure
    )
  },
  checkCollectionStatus (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/loan/check-collection-status`,
      input,
      success,
      failure
    )
  },
  compareTransactionFee (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/loan/compare-transaction-fee`,
      input,
      success,
      failure
    )
  },
  getPaymentOption (input, success, failure) {
    return ajax.get(
      `${BASE_URL}/loan/get-payment-option`,
      input,
      success,
      failure
    )
  },
  // pre organization
  parsePathResource (resource, success, failure) {
    return ajax.get(
    `${BASE_URL}/parse-path-resource`,
    { resource },
    success,
    failure
    )
  },
  parseReturningResource (resource, success, failure) {
    return ajax.get(
      `${BASE_URL}/parse-returning-resource`,
      { resource },
      success,
      failure
    )
  },
  parseCompleteResource (resource, success, failure) {
    return ajax.get(
    `${BASE_URL}/parse-complete-resource`,
    { resource },
    success,
    failure
    )
  },
  listSection (success, failure) {
    return ajax.get(
    `${BASE_URL}/list-section`,
    null,
    success,
    failure
    )
  },
  checkBlackList (success, failure) {
    return ajax.get(
    `${BASE_URL}/check-black-list`,
    null,
    success,
    failure
    )
  },
  calculateQualifiedAmount (success, failure) {
    return ajax.post(
    `${BASE_URL}/calculate-qualified-amount`,
    null,
    success,
    failure
    )
  },
  mapBankInfo (routingNo, success, failure) {
    return ajax.get(
      BASE_URL + '/get-bank-info',
      { rn: routingNo },
      success,
      failure
    )
  },
  getLoanInfo (loanNo, success, failure) {
    return ajax.get(
      `${BASE_URL}/get-payment-option`,
      { loanNo },
      success,
      failure
    )
  },
  savePaymentOption (input, success, failure) {
    return ajax.post(
    `${BASE_URL}/save-payment-option`,
    input,
    success,
    failure
    )
  },
  getBankInfo (loanNo, success, failure) {
    return ajax.get(
    `${BASE_URL}/get-bank-info`,
    { loanNo },
    success,
    failure
    )
  },
  getProvider (portfolioId, success, failure) {
    return ajax.get(
      BASE_URL + '/get-provider',
      { portfolioId },
      success,
      failure
    )
  },
  createRequestCode (input, success, failure) {
    return ajax.post(
      BASE_URL + '/create-request-code',
      input,
      success,
      failure
    )
  },
  getRequestStatus (input, success, failure) {
    return ajax.get(
      BASE_URL + '/get-request-status',
      input,
      success,
      failure
    )
  },
  getReport (input, success, failure) {
    return ajax.get(
    `${BASE_URL}/get-request-details`,
    input,
    success,
    failure
    )
  },
  calculatePaymentSchedule (input, success, failure) {
    return ajax.post(
      BASE_URL + '/calculate-payment-schedule',
      input,
      success,
      failure
    )
  },
  getLoanDetail (success, failure) {
    return ajax.get(
      BASE_URL + '/payment-detail',
      null,
      success,
      failure
    )
  },
  sendLoanAgreement (input, success, failure) {
    return ajax.post(
      BASE_URL + '/send-loan-agreement',
      input,
      success,
      failure
    )
  },
  paperCheck (input, success, failure) {
    return ajax.post(
      BASE_URL + '/paper-check',
      input,
      success,
      failure
    )
  },
  getPersonalData (success, failure) {
    return ajax.get(
      BASE_URL + '/get-personal',
      null,
      success,
      failure
    )
  },
  listConsentStatus (success, failure) {
    return ajax.get(
      BASE_URL + '/check-consent-status',
      null,
      success,
      failure
    )
  },
  batchSaveConsentStatus (input, success, failure) {
    return ajax.post(
      BASE_URL + '/batch-save-consent-status',
      input,
      success,
      failure
    )
  },
  parseSignatureResource (resource, success, failure) {
    return ajax.get(
      `${BASE_URL}/parse-signature-resource`,
      { resource },
      success,
      failure
    )
  },
  simulateData (input, success, failure) {
    return ajax.post(
      BASE_URL + '/get-simulated-data',
      input,
      success,
      failure
    )
  },
  checkCoupon (success, failure) {
    return ajax.get(
      BASE_URL + '/check-coupon',
      null,
      success,
      failure
    )
  },
  uploadBankStatement (input, success, failure) {
    return ajax.post(
      BASE_URL + '/upload-bank-statement',
      input,
      success,
      failure
    )
  },
  checkRiskInfo (success, failure) {
    return ajax.get(
      BASE_URL + '/check-risk-info',
      null,
      success,
      failure
    )
  },
  recordFailureData (input, success, failure) {
    return ajax.post(
      BASE_URL + '/record-business-data',
      input,
      success,
      failure
    )
  }
}

export { OnlineApi }
