import { Tool } from '@/assets/js/util/tool'
const tool = new Tool()
// 一些自带的校验规则
// 最小长度 min: value, such as min: 6
// 最大长度 max: value
// 最小值 min_value: value
// 最大值 max_value: value
// 几个数字 digits: value, such as digits: 6
// 数字 numeric, 包含٠١٢٣٤٥٦٧٨٩
// 小数 decimal
// 邮箱 email
const validation = {
  notEmpty: {
    validate: value => {
      return (typeof value === 'string' && value.length > 0) || typeof value === 'number'
    }
  },
  phone: {
    validate: value => {
      const regPhone = /^[2-9]\d{9}$/
      value = value.replace(/\D/ig, '')
      return tool.isNotEmpty(value) && regPhone.test(value)
    },
    getMessage: () => 'Invalid phone number!'
  },
  fax: {
    validate: value => /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
  },
  number: {
    validate: value => /^(-|\+)?\d+(\.\d+)?$/.test(value),
    getMessage: () => 'Should be a digit'
  },
  currency: {
    validate: value => {
      const regCurrency = /^(-*[0-9]+[\d]*(.[0-9]{1,2})?)$/
      return tool.isNotEmpty(value) && regCurrency.test(value)
    },
    getMessage: () => 'Invalid Currency format, should be a number'
  },
  specialCharacters: {
    validate: value => {
      const regSpecialCharacters = /[-!_@#$%,*:\\/'"+`~^]/im
      return tool.isNotEmpty(value) && !regSpecialCharacters.test(value)
    },
    getMessage: (field) => `${field} found special character`
  },
  bankRoutingNo: {
    validate: value => {
      const regBankRoutingNo = /^\d{9}$/
      return tool.isNotEmpty(value) && regBankRoutingNo.test(value)
    },
    getMessage: (field) => `${field} should be 9 digits`
  },
  bankAccountNo: {
    validate: value => {
      const regBankAccountNo = /^\d*$/
      return tool.isNotEmpty(value) && regBankAccountNo.test(value)
    },
    getMessage: (field) => `${field} should be a digit`
  },
  ssn: {
    validate: value => {
      const regSSN = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/
      value = value.replace(/[^0-9]/ig, '')
      return tool.isNotEmpty(value) && regSSN.test(value)
    },
    getMessage: (field) => `${field} should be 9 digits`
  },
  zip: {
    validate: value => {
      const regZipCode = /^\d{5}$/
      return tool.isNotEmpty(value) && regZipCode.test(value)
    },
    getMessage: (field) => `${field} should be 5 digits`
  },
  matchTailNo: {
    validate: (value, [matchValue]) => {
      if (tool.isEmpty(matchValue)) {
        return true
      }
      return value.substr(value.length - 4) === matchValue
    },
    getMessage: (field, [matchValue]) => {
      if (field.indexOf('Routing') !== -1) {
        field = 'Routing Number'
      } else if (field.indexOf('Account') !== -1) {
        field = 'Account Number'
      }
      return 'Please enter the ' + field + ' ends with ' + matchValue
    },
    params: ['matchValue']
  },
  alphabetWithSpace: {
    // 可带空格的字母
    validate: value => /^[a-zA-Z\s]+$/.test(value),
    getMessage: (field) => `${field} can only consist of letters`
  },
  name: {
    validate: value => {
      const regName = /^[a-zA-Z]+(\s+[a-zA-Z]+)*$/im
      return tool.isNotEmpty(value) && regName.test(value)
    },
    getMessage: (field) => `${field} can only contain letters`
  },
  noSpaceAtStartAndEnd: {
    // 首尾不可空格
    validate: value => !/^\s+|\s+$/.test(value),
    getMessage: (field) => `${field} should not have space at the beginning and end`
  },
  noContinuousSpace: {
    // 不能连续空格
    validate: value => /^\S+(\s\S+)*$/.test(value),
    getMessage: (field) => `${field} should not have continuous spaces`
  },
  email: {
    validate: value => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value),
    getMessage: (field) => `${field} must be a valid email`
  },
  continuousSpace: {
    validate: value => {
      const continuousSpaceReg = /\s{2,}/
      return tool.isNotEmpty(value) && !continuousSpaceReg.test(value)
    },
    getMessage: (field) => 'No consecutive spaces'
  },
  positiveInteger: {
    validate: value => /^\d*$/.test(value),
    getMessage: () => 'Should be a positive digit'
  }
}

export { validation }
