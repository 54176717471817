import Vue from 'vue'

import store from './assets/js/store/index'
import router from './settings/router/'
import vuetify from './plugins/vuetify'

import '@/plugins/perfect-scrollbar'
import '@/plugins/workarea-layout'

import './plugins/register-service-worker'
import './plugins/storage'
import './plugins/vee-validate'
import '@/assets/styles/fakeLoader.css'
import '@/assets/styles/components-icon.css'
import '@/assets/js/util/prototype'

import '@/assets/styles/framesets/main.sass'

import App from './App.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

// Plugin simple-uploader
import uploader from 'vue-simple-uploader'

// 下划线
import underscore from 'vue-underscore'
import VueTheMask from 'vue-the-mask'

Vue.use(uploader)

Vue.use(underscore)
Vue.use(VueTheMask)

Vue.component(VuePerfectScrollbar.name, VuePerfectScrollbar)

Vue.config.productionTip = false

Vue.prototype.store = store

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
})

app.$mount('#app')

const isDebugMode = process.env.VUE_APP_TITLE !== 'production'
Vue.config.devtools = isDebugMode
Vue.config.productionTip = isDebugMode
